(function(w, d) {

  var closestParentWithClass = function(event, className) {
    return event.target.closest('.' + className);
  };
  
  var $popup = $('.js-popup');
  var $popupButtonsWrapper = $('.js-popup-buttons-wrapper');

  var popup = {};

  popup.openPopup = function(cb) {
    $popup.addClass('_active');

    if (typeof cb === 'function') {
      cb();
    }
  };

  popup.closePopup = function(cb) {
    $popup.removeClass('_active');

    if (typeof cb === 'function') {
      cb();
    }
  };

  popup.isPopupButtonClicked = function(e) {
    return closestParentWithClass(e, 'js-popup-button') || e.target.classList.contains('js-popup-button');
  }

  popup.init = function(options) {
    if ($popup) {
      $.each($popupButtonsWrapper, function(index, wrapper) {
        wrapper.addEventListener('click', function(e) {
          if (popup.isPopupButtonClicked(e)) {
            popup.openPopup(function() {
              options.onOpen(e);
            });
          }
        });
      });

      $popup.on('click', function(e) {
        popup.closePopup(function() {
          options.onClose();
        });
      });
    }
  };

  w.popup = popup;
})(window, document);
